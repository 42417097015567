.u-ta {
  &-c {
    text-align: center;
  }
  &-r {
    text-align: right;
  }
  &-j{
    text-align: justify;
  }
}
