.c-clip{
	&--type01{
		img{
			clip-path: polygon(0 31.17%, 100% 0%, 100% 68.83%, 0% 100%);//866:270
		}
	}
	&--type02{
		img{
			clip-path: polygon(0 24.32%, 100% 0%, 100% 75.68%, 0% 100%);//1110:270
			@include sp{
				clip-path: polygon(0 12.5%, 100% 0%, 100% 87.5%, 0% 100%);//1080:135
			}
		}
	}
	&--type03{
		img{
			clip-path: polygon(0 20.61%, 100% 0%, 100% 79.39%, 0% 100%);//655:135
			@include sp{

			}
		}
	}
	&--type04{
		img{
			clip-path: polygon(0 0, 100% 0%, 100% 83.54%, 0% 100%);//656:108
			@include sp{

			}
		}
	}
	&--type05{
		img{
			clip-path: polygon(0 18.75%, 100% 0%, 100% 81.25%, 0% 100%);//1440:270
			@include sp{
				clip-path: polygon(0 7.94%, 100% 0%, 100% 92.06%, 0% 100%);//1700:135
			}
		}
	}
	&--type06{
		img{
			clip-path: polygon(0 21.77%, 100% 0%, 100% 78.23%, 0% 100%);//1240:270
			@include sp{

			}
		}
	}
	&--type07{
		img{
			clip-path: polygon(0 9.03%, 100% 0%, 100% 90.97%, 0% 100%);//2990:270
			@include sp{

			}
		}
	}
	&--type08{
		img{
			clip-path: polygon(0 42.85%, 100% 0%, 100% 57.15%, 0% 100%);//630:270
			@include sp{
				clip-path: polygon(0 33.75%, 100% 0%, 100% 66.25%, 0% 100%);//400:135
			}
		}
	}
	&--type09{
		img{
			clip-path: polygon(0 18.36%, 100% 0%, 100% 81.64%, 0% 100%);//1470:270
			@include sp{
				clip-path: polygon(0 11.74%, 100% 0%, 100% 88.26%, 0% 100%);//1150:135
			}
		}
	}
	&--type10{
		img{
			clip-path: polygon(0 16.66%, 100% 0%, 100% 100%, 0% 100%);//1620:270
			@include sp{
				clip-path: polygon(0 9.92%, 100% 0%, 100% 100%, 0% 100%);//1360:135
			}
		}
	}
	&--type11{
		img{
			clip-path: polygon(0 24.32%, 100% 0%, 100% 75.68%, 0% 100%);//1110:270
			@include sp{
				clip-path: polygon(0 15%, 100% 0%, 100% 85%, 0% 100%);//900:135
			}
		}
	}
}
