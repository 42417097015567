/*------------------------
 slick-theme.css
------------------------*/


/* Slider */

.slick-list {
    .slick-loading & {
        background: #fff slick-image-url("../assets/images/common/ajax-loader.gif") center center no-repeat;
    }
}


/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: 40px;
}

.slick-dots {
    position: absolute;
    display: block;
    list-style: none;
    text-align: center;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 0;
    padding-top: 4px;
    li {
        position: relative;
        display: inline-block;
        line-height: 1;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        button {
            display: block;
            cursor: pointer;
            color: transparent;
            background: transparent;
            border: 0;
            line-height: 1;
            outline: none;
            font-size: 0px;
            padding: 15px;
            transition: opacity .3s;
            &:hover, &:focus {
                outline: none;
                &:before {
                    opacity: .7;
                }
            }
            &:before {
                position: absolute;
                content: "";
                width: 10px;
                height: 10px;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                background-color: #999;
                border-radius: 50%;
            }
        }
        &.slick-active button:before {
            background-color: black;
        }
    }
}


/* Arrows */

.slick-arrow{
    position: absolute;
    z-index: 1;
    display: block;
    cursor: pointer;
    border: none;
    outline: none;
    color: transparent;
    line-height: 1;
    width: 50px;
    height: 50px;
    background-color: #CCC;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    border-radius: 50%;
    transition: background-color .3s;
    &.slick-prev,&.slick-next{
        &:before{
            position: absolute;
            content: "";
            width: 20%;
            height: 20%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            border: 1px solid transparent;
            border-bottom-color: #FFF;
            border-left-color: #FFF;
        }
    }
    &.slick-prev{
        left: 0;
        &:before{
            transform: translateX(20%) rotate(45deg);
        }
    }
    &.slick-next{
        right: 0;
        &:before{
            transform: translateX(-20%) rotate(-135deg);
        }
    }
    &:hover{
        background-color: #999;
    }
}
