@charset "UTF-8";

/*-------------------------------
  scroll_header用
-------------------------------*/

.scroll_header{
  transition: .5s;
}

/*-------------------------------
  header
-------------------------------*/


.l-header{
  width: 100%;
  position: fixed;
  z-index: 888;
  top: 0;
  left: 0;
  &_inner{
    display: flex;
    align-items: center;
    padding: calc(45 / 1512 * 100%) calc(50 / 1512 * 100%);
    @include sp {
      padding: calc(45 / 750 * 100%) calc(45 / 750 * 100%);
    }
  }
}




.header_logo{
  width: calc(189 / 1372 * 100%);
  height: auto;
  line-height: 1;
  margin-right: auto;
  z-index: 777;
  @include sp{
    width: calc(188 / 660 * 100%);
  }
  a{
    display: block;
    img{
      width: 100%;
    }
  }
}

.header_apply{
  width: calc(366 / 1372 * 100%);
  @include sp{
    width: calc(360 / 660 * 100%);
  }
}




/*-------------------------------
  nav PC
-------------------------------*/


#navGlobal{
  ul.menu{
    display: flex;
    @include sp {
      flex-direction: column;
    }
    & > li.menu-item{
      position: relative;
      & > a{
        display: flex;
        justify-content: center;
        align-items: center;
        color: $base_color;
        font-size: 16px;
        padding: 0 1em;
        height: 80px;
        @include pc {
          &:hover{
            
          }
        }
        @include sp {
          font-size: 1.6rem;
        }
      }
      &.menu-item-has-children{
        & > a{
          @include sp {
            pointer-events: none;
          }
        }
      }
      &.menu_open{
        
      }
      & > ul.sub-menu{
        display: none;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        @include sp {
          position: static;
          transform: none;
        }
        & > li{
          & > a{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 200px;
            padding: 1.2em 1em;
            color: #FFF;
            font-size: 14px;
            background: $base_color;
            transition: background-color .3s;
            @include pc {
              &:hover{
                
              }
            }
            @include sp {
              width: 100%;
              font-size: 1.3rem;
            }
          }
        }
      }
    }
  }
}


.hover_bd{
  position: relative;
  cursor: pointer;
  &:before{
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    bottom: -.3em;
    left: 0;
    background-color: $base_color;
    transform: scale(0,1);
    transform-origin: right top;
    transition: transform .3s;
    @include sp {
      display: none;
    }
  }
  @include pc {
    &:hover{
      &:before{
        transform-origin: left top;
        transform: scale(1,1);
      }
    }
  }
}


/*-------------------------------
  toggle PC
-------------------------------*/

#toggle {
  position: fixed;
  right: 0;
  top: 0;
  width: 60px;
  height: 60px;
  cursor: pointer;
  z-index: 999;
  @include pc {
    display: none;
  }
  @include sp {
    width: 60px;
    height: 60px;
    right: 0;
    background-color: $base_color;
  }
  @include xs {
    width: 50px;
    height: 50px;
  }
  span{
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 35%;
    height: 2px;
    background: #FFF;
    transform: translate(-50%,-50%);
    transition: .3s;
    &:before,&:after{
      display: block;
      position: absolute;
      content: "";
      width: 100%;
      height: 2px;
      top: -6px;
      left: 0;
      background: #FFF;
      transition: .3s;
    }
    &:before{
      top: -8px;
      @include xs {
        top: -7px;
      }
    }
    &:after{
      top: 8px;
      @include xs {
        top: 7px;
      }
    }
  }
  &.active{
    span{
      background: 0,0;
      transition: .3s;
      &:before{
        top: 0;
        left: 0;
        transform: rotate(-45deg);
        transition: .3s;
      }
      &:after{
        top: 0;
        left: 0;
        transform: rotate(45deg);
        transition: .3s;
      }
    }
  }
}




