//color
$base_color: #000;
$base_color_li: lighten($base_color, 8%);
$base_color_dk: darken($base_color, 8%);

$green_line: #06C755;

// transition
$anime: 0.3s ease;

//フォント指定
@font-face {
  font-family: 'DINPro-Bold';
  src: url('../../assets/fonts/DINPro-Bold.woff') format('woff'), /* woff - Modern Browsers */
       url('../../assets/fonts/DINPro-Bold.ttf') format('truetype'),/* ttf - Safari, Android, iOS */
       url('../../assets/fonts/DINPro-Bold.otf') format('opentype'),
}
@font-face {
  font-family: 'DINPro-Medium';
  src: url('../../assets/fonts/DINPro-Medium.woff') format('woff'), /* woff - Modern Browsers */
       url('../../assets/fonts/DINPro-Medium.ttf') format('truetype'),/* ttf - Safari, Android, iOS */
       url('../../assets/fonts/DINPro-Medium.otf') format('opentype'),
}
@font-face {
  font-family: 'DINPro-Light';
  src: url('../../assets/fonts/DINPro-Light.woff') format('woff'), /* woff - Modern Browsers */
       url('../../assets/fonts/DINPro-Light.ttf') format('truetype'),/* ttf - Safari, Android, iOS */
       url('../../assets/fonts/DINPro-Light.otf') format('opentype'),
}

//フォント指定
$fontDINpro_Bold: 'DINPro-Bold', sans-serif;
$fontDINpro_Medium: 'DINPro-Medium', sans-serif;
$fontDINpro_Light: 'DINPro-Light', sans-serif;
$font_yugo: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic",'ヒラギノ角ゴシック','Hiragino Sans','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3','メイリオ', Meiryo,sans-serif;
$font_yumin: 'Yu Mincho Light','YuMincho','Yu Mincho','游明朝体','Yu Mincho Light','YuMincho','Yu Mincho','游明朝体',serif;

//---------------------------
