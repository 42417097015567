.u-fw{
	&-bold{
    font-weight: 700;
  }
	&-mid{
    font-weight: 500;
  }
	&-normal{
    font-weight: 400;
  }
}