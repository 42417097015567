.l-wrap {
  width: 100%;
  margin: 0 auto;
  &_inner {
    width: 100%;
    padding-left: 40px;
		padding-right: 40px;
    margin: 0 auto;
    @include pc {
      max-width: 1080px;
    }
    @include sp {
      padding-left: 2rem;
			padding-right: 2rem;
    }
  }
}