.c-txt{
	line-height: calc(28/16);
	$this: &;
	font-weight: 500;
	&-14{
		@extend #{$this};
		@include fz(14);
	}
	&-16{
		@extend #{$this};
		@include fz(16);
	}
	&-18{
		@extend #{$this};
		@include fz(18);
	}
	&-20{
		@extend #{$this};
		@include fz(20);
		@include sp{
			font-size: vw(28, 750);
		}
	}
}
