.u-txt {
  &-indent {
    padding-right: 1em;
    text-indent: -1em;
  }
  /* white-space */
  &-nowrap{
    white-space: nowrap;
  }
  /* 上付き文字 */
  &-sup{
    font-size: .6em;
    vertical-align: super;
  }
/* 下付き文字 */
  &-sub{
    font-size: .6em;
    vertical-align: baseline;
  }
  &-yugo{
    font-family: $font_yugo;
    font-weight: 500;
  }
}