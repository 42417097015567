.p-index{
  &Hero{
    position: relative;
    @include pt(50);
    @include pb(150);
    @include pcOver{
      padding-top: 0;
      padding-bottom: 20px;
    }
    @include sp{
      padding-bottom: 52vw;
    }
    &_bg{
      position: relative;
      mask-image: url(../../assets/img/bg_indexHero_mask.svg);
      @include sp{
        width: 180%;
        mask-image: url(../../assets/img/bg_indexHero_mask_sp.svg);
      }
      video{
        height: calc(851 / 1512 * 100vw);
        @include sp{
          height: calc(720 / 750 * 100vw);
        }
      }
      &:before{
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: url(../../assets/img/bg_indexHero_mask.svg) no-repeat;
        background-size: 100%;
        opacity: .6;
        @include sp{
          background: url(../../assets/img/bg_indexHero_mask_sp.svg) no-repeat;
        }
      }
    }
    &_inner{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
    }
    &_row{
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(1372 / 1512 * 100%);
      margin: auto auto calc(20 / 1512 * 100%);
      @include pcOver{
        width: 1372px;//デフォルト
        width: 1200px;
        margin: auto auto 50px;
      }
      @include sp{
        flex-direction: column-reverse;
        align-items: center;
        width: 100%;
      }
      &_img{
        width: calc(680 / 1372 * 100%);
        @include sp{
          width: calc(680 / 750 * 100%);
          margin: 0 auto 0 -5%;
        }
      }
      &_txt{
        position: relative;
        z-index: 1;
        width: calc(692 / 1372 * 100%);
        @include sp{
          width: 100%;
        }
        &_catch{
          width: calc(600 / 692 * 100%);
          text-align: center;
          @include sp{
            width: calc(539 / 750 * 100%);
            margin: calc(180 / 750 * 100%) auto 0;
          }
          &--txt{
            display: inline-block;
            width: calc(516 / 600 * 100%);
          }
          &--main{
            display: inline-block;
            width: 100%;
            margin: calc(20 / 600 * 100%) auto calc(20 / 600 * 100%);
          }
          &--label{
            display: inline-block;
            width: calc(260 / 600 * 100%);
            @include sp{
              width: 100%;
            }
            &:not(:nth-child(2n)){
              @include pc{
                margin-right: calc(20 / 600 * 100%);
              }
              @include sp{
                margin-bottom: calc(20 / 600 * 100%);
              }
            }
          }
          >div{
            display: flex;
            justify-content: center;
            @include sp{
              position: absolute;
              width: calc(216 / 750 * 100%);
              top: 110%;
              right: calc(45 / 750 * 100%);
              align-items: flex-end;
              
              flex-direction: column;
            }
          }
        }
      }
    }
    &_imgSwich{
      &--01{
        position: relative;
        >li{
          backface-visibility: hidden;
          &:nth-child(1){
            opacity: 0;
            animation-name: heroSwich;
            animation-duration: 10s;
            animation-delay: 7.5s;
            animation-timing-function: ease;
            animation-iteration-count: infinite;
          }
          &:nth-child(2){
            position: absolute;
            opacity: 0;
            top: 0;
            left: 0;
            animation-name: heroSwich;
            animation-duration: 10s;
            animation-delay: 2.5s;
            animation-timing-function: ease;
            animation-iteration-count: infinite;
          }
        }
        &.js-firstSpeed{
          >li{
            backface-visibility: hidden;
            &:nth-child(1){
              opacity: 0;
              animation-name: heroSwich;
              animation-duration: 5s;
              animation-delay: 0s;
              animation-timing-function: ease;
              animation-iteration-count: infinite;
            }
            &:nth-child(2){
              position: absolute;
              opacity: 0;
              top: 0;
              left: 0;
              animation-name: heroSwich;
              animation-duration: 5s;
              animation-delay: 2.5s;
              animation-timing-function: ease;
              animation-iteration-count: infinite;
            }
          }
        }
      }
      &--02{
        position: relative;
        >li{
          backface-visibility: hidden;
          &:nth-child(1){
            opacity: 0;
            animation-name: heroSwich_02;
            animation-duration: 10s;
            animation-timing-function: ease;
            animation-iteration-count: infinite;
          }
          &:nth-child(2){
            position: absolute;
            opacity: 0;
            top: 0;
            left: 0;
            animation-name: heroSwich_02;
            animation-duration: 10s;
            animation-delay: 5s;
            animation-timing-function: ease;
            animation-iteration-count: infinite;
          }
        }
      }
      &--03{
        position: relative;
        >li{
          backface-visibility: hidden;
          position: relative;
          &:nth-child(1){
            // &:after {
            //   clip-path: inset(0);
            //   transition: clip-path .8s;
            // }
            opacity: 0;
            clip-path: inset(0);
            animation-name: slideScreen;
            animation-duration: 10s;
            animation-timing-function: ease;
            animation-iteration-count: infinite;
          }
          &:nth-child(2){
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            clip-path: inset(0);
            animation-name: slideScreen;
            animation-duration: 10s;
            animation-delay: 5s;
            animation-timing-function: ease;
            animation-iteration-count: infinite;
          }
        }
      }
    }
  }

  &Intro{
    position: relative;
    &_bg{
      margin-top: calc(-260 / 1512 * 100%);
      @include sp{
        margin-top: 0;
      }
    }
    &_inner{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      padding-top: calc(270 / 1520 * 100%);
      display: flex;
      flex-direction: column;
      text-align: center;
      @include sp{
        padding-top: calc(135 / 750 * 100%);
      }
    }
    &_ttl{
      line-height: calc(60/30);
      font-weight: 700;
      @include fz(36);
      font-family: $font_yumin;
    }
    &_list{
      display: flex;
      width: calc(1372 / 1512 * 100%);
      margin: auto auto 0;
      @include sp{
        width: 100%;
        flex-direction: column-reverse;
      }
      >figure{
        position: relative;
        z-index: 1;
        width: calc(606 / 1372 * 100%);
        margin-bottom: calc(13 / 1372 * 100%);
        @include sp{
          width: calc(400 / 750 * 100%);
          margin: 0 auto 0;
        }
        img{
          //606:656
        }
      }
      >div{
        position: relative;
        width: calc(766 / 1372 * 100%);
        @include sp{
          width: 100%;
        }
        >ul{
          position: absolute;
          left: -28%;
          top: 4%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          @include sp{
            position: static;
          }
          >li{
            display: inline-block;
            @include fz(32);
            font-family: $font_yumin;
            color: #FFF;
            @include sp{
              @include fz(31);
            }
            >div{
              display: inline-block;
              font-weight: 500;
              transform: rotate(-10deg) skew(-10deg);
              padding: .6em 1em .6em 2.5em;
              line-height: calc(28/16);
              background-color: #000;
              @include sp{
                padding: .6em .8em;
              }
              span{
                display: inline-block;
                transform: skew(10deg);
              }
            }
            &:nth-child(1){
              >div{
                padding-left: 2.5em;
                @include sp{
                  padding-left: 1em;
                }
              }
            }
            &:nth-child(2){
              >div{
                padding-left: 3em;
                @include sp{
                  padding-left: 1em;
                }
              }
            }
            &:nth-child(3){
              >div{
                padding-left: 3.5em;
                @include sp{
                  padding-left: 1em;
                }
              }
            }
            &:not(:last-child){
              margin-bottom: .8em;
            }
          }
        }
      }
    }
  }

  &Point{
    position: relative;
    &_head{
      position: relative;
      &_bg{
        margin-top: calc(-270 / 1512 * 100%);
      }
      &_inner{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding-top: calc((270 + 40) / 1512 * 100%);
        display: flex;
        justify-content: center;
        @include sp{
          padding-top: calc((135 + 90) / 750 * 100%);
        }
      }
      &_ttl{
        width: calc(720 / 1512 * 100%);
        @include sp{
          width: calc(648 / 750 * 100%);
        }
      }
    }
    &_list{
      &Item{
        display: flex;
        @include sp{
          flex-direction: column-reverse;
        }
        &_img{
          @include pc{
            width: 50%;
          }
        }
        &_txt{
          @include pc{
            width: 50%;
          }
          line-height: calc(40/20);
          display: flex;
          align-items: center;
          &Area{
            width: 100%;
          }
          &_ttl{
            display: flex;
            align-items: center;
            @include fz(36);
            border-bottom: 1px solid $base_color;
            margin-bottom: .5em;
            >span{
              display: inline-block;
              &:nth-child(1){
                line-height: calc(131 / 160);
                font-size: 4.444em;
                font-family: $fontDINpro_Light;
                font-weight: 700;
                padding-right: .3em;
              }
              &:nth-child(2){
                font-family: $font_yumin;
                font-weight: 700;
                padding-top: .3em;
              }
            }
          }
        }
        &:nth-child(odd){
          margin-top: calc(-270 / 1512 * 100%);
          flex-direction: row-reverse;
          @include sp{
            flex-direction: column-reverse;
          }
          .p-indexPoint_listItem_txt{
            padding-left: calc(200 / 1512 * 100%);
            padding-top: calc((108 + 100) / 1512 * 100%);
            @include sp{
              padding-left: calc(45 / 750 * 100%);
              padding-top: calc((135 + 20) / 750 * 100%);
            }
          }
          p{
            @include sp{
              padding-right: calc(45 / 750 * 100%);
            }
          }
        }
        &:nth-child(even){
          .p-indexPoint_listItem_txt_ttl{
            @include pc{
              padding-left: calc(216 / 1512 * 100%);
            }
          }
          .p-indexPoint_listItem_txt{
            padding-top: 0;
            padding-left: initial;
            padding-bottom: calc((108 + 0) / 1512 * 100%);
            padding-right: calc(200 / 1512 * 100%);
            @include sp{
              padding-left: calc(45 / 750 * 100%);
              padding-right: 0;
              padding-top: calc(20/ 750 * 100%);
              padding-bottom: 0;
            }
          }
          p{
            @include pc{
              padding-left: calc(30 / 216 * 100%);
            }
            @include sp{
              padding-right: calc(45 / 750 * 100%);
            }
          }
        }
      }
    }
  }
  &About{
    position: relative;
    z-index: 1;
    &_bg{
      margin-top: calc(-135 / 1512 * 100%);
      @include sp{
        margin-top: calc(-135 / 750 * 100%);
        height: calc(1700 / 750* 100vw);
      }
    }
    &_inner{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      padding-top: calc((270) / 1512 * 100%);
      @include sp{
        padding-top: calc((135 + 20) / 750 * 100%);
      }
    }
    &_ttl{
      text-align: center;
      @include fz(48);
      line-height: calc(84/48);
      font-family: $font_yumin;
      >span{
        position: relative;
        display: inline-block;
        padding-bottom: .3em;
        &:before{
          position: absolute;
          content: "";
          width: 1.5em;
          height: 1px;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          background-color: $base_color;
        }
      }
    }
    &_row{
      display: flex;
      width: calc(1080 / 1512 * 100%);
      margin: auto;
      @include mt(140);
      @include sp{
        flex-direction: column-reverse;
        width: calc(660 / 750 * 100%);
        margin: auto;
        @include mt(70);
      }
      &_txt{
        @include pc{
          width: calc(500 / 1080 * 100%);
          margin-right: calc(40 / 1080 * 100%);
        }
        &_ttl{
          @include fz(40);
          line-height: calc(60/40);
          font-family: $font_yumin;
          margin-bottom: 1em;
        }
      }
      &_img{
        @include pc{
          width: calc(540 / 1080 * 100%);
        }
        @include sp{
          @include mb(50);
        }
      }
    }
    &_list{
      display: flex;
      justify-content: space-between;
      width: calc(830 / 1512 * 100%);
      margin: auto;
      @include mt(60);
      @include sp{
        width: calc(660 / 750 * 100%);
        margin-left: auto;
        margin-right: auto;
      }
      >li{
        width: calc(228 / 830 * 100%);
        border: 1px solid $base_color;
        border-radius: 100vw;
      }
    }
  }
  &Feature{
    position: relative;
    &_wrap{
      margin-top: calc(-270 / 1512 * 100%);
      margin-bottom: calc(-270 / 1512 * 100%);
      padding-top: calc(270 / 1512 * 100%);
      padding-bottom: calc(270 / 1512 * 100%);
    }
    &:before{
      position: absolute;
      z-index: -1;
      content: "";
      top: calc(-270 / 1512 * 100vw);
      width: 100%;
      //height: 300vw;
      height: 200vh;
      left: 0;
      background-image: url(../../assets/img/bg_indexFeature.jpg);
      //background-image: url(../../assets/img/bg_indexCareer_flow_pc.jpg);
      background-repeat: no-repeat;
      background-color: green;
      background-size: cover;
      //transform: skewY(-10deg);
      @include sp{
        height: 150vh;
      }
    }
    // &_bg{
    //   //margin-top: calc(-270 / 1512 * 100%);
    //   display: none;
    // }
    &_inner{
      //display: flex;
      //align-items: center;
      // position: absolute;
      // width: 100%;
      // height: 100%;
      // top: 0;
      // left: 0;
      // padding-top: calc(270 / 1512 * 100%);
      // padding-bottom: calc(270 / 1512 * 100%);
    }
    &_list{
      display: flex;
      &Item{
        display: flex;
        align-items: center;
        height: auto;
        min-height: 100vh;
        @include sp{
          min-height: 100vh;
        }
        >div{
          display: flex;
          padding: 0 calc(120 / 660 * 100%);
          flex-direction: column;
          @include sp{
            padding: calc(270 / 1512 * 100vw) calc(60 / 660 * 100%)
          }
        }
        figure{
          @include mb(30);
        }
        &_ttl{
          @include fz(36);
          line-height: calc(48/36);
          font-family: $font_yumin;
          @include mb(15);
        }
        .c-txt-16{
          min-height: 10em;
          line-height: calc(32/16);
          @include sp{
            min-height: 12.5em;
            font-size: vw(28, 750);
          }
        }
        &._reverce{
          div{
            figure{
              @include mt(30);
              margin-bottom: 0;
              order: 1;
            }
          }
        }
      }
    }
  }
  &Recruit{
    position: relative;
    &_head{
      position: relative;
      &_bg{
        margin-top: calc(-270 / 1512 * 100%);
      }
      &_inner{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding-top: calc((270 + 40) / 1512 * 100%);
        display: flex;
        justify-content: center;
        @include sp{
          padding-top: calc((135 + 90) / 750 * 100%);
        }
      }
      &_ttl{
        width: calc(720 / 1512 * 100%);
        @include sp{
          width: calc(648 / 750 * 100%);
        }
      }
    }
    &_body{
      position: relative;
      &_bg{
        margin-top: calc(-270 / 1512 * 100%);
        @include sp{
          height: calc(3960 / 750* 100vw);
          >img{
            &:not(:first-child){
              margin-top: calc(-135 / 750 * 100vw);
            }
          }
        }
      }
      &_inner{
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding-top: calc((270) / 1512 * 100%);
        @include sp{
          padding-top: calc((135 + 40) / 750 * 100%);
        }
      }
      &_item{
        @include sp{
          padding: 0 calc(45 / 750 * 100%);
        }
        &:not(:last-child){
          @include mb(120);
        }
        &_ttl01{
          text-align: center;
          @include fz(48);
          line-height: calc(72/48);
          font-family: $font_yumin;
          >span{
            display: inline-block;
            position: relative;
            padding: .2em .8em;
            &:before,&:after{
              position: absolute;
              content: "";
              width: 100%;
              height: 1px;
              left: 0;
              background-color: $base_color;
            }
            &:before{
              top: 0;
            }
            &:after{
              bottom: 0;
            }
          }
        }
        &_ttl02{
          text-align: center;
          @include fz(36);
          line-height: calc(80/36);
          font-family: $font_yumin;
          >span{
            display: inline-block;
          }
        }
        &_lead{
          @include mt(50);
          text-align: center;
        }
        &_table{
          width: calc(900 / 1512 * 100%);
          margin: auto;
          @include mt(60);
          background-color: rgba(#000,.8);
          padding: calc(20 / 1512 * 100%) calc(30 / 1512 * 100%);
          @include sp{
            width: 100%;
            padding: 0 1.5rem;
          }
          table{
            border-collapse: collapse;
            width: 100%;
            tr{
              &:not(:last-child){
                th,td{
                  border-bottom: 1px solid #FFF;
                }
                th{
                  @include sp{
                    border-bottom: none;
                  }
                }
              }
              th,td{
                line-height: calc(24/16);
                @include fz(16);
                font-weight: 500;
                vertical-align: middle;
                color: #FFF;
                padding: 2em 1em;
                @include sp{
                  display: block;
                  font-size: vw(26, 750);
                }
              }
              th{
                font-weight: 700;
                width: 20%;
                @include sp{
                  width: 100%;
                  text-align: left;
                  padding: 1.5em 0 .5em;
                }
              }
              td{
                width: 80%;
                text-align: left;
                padding-left: 2em;
                @include sp{
                  width: 100%;
                  padding: .5em 0 1.5em;
                }
              }
            }
          }
        }
        &_incentive{
          display: flex;
          align-items: center;
          width: calc(780 / 1512 * 100%);
          margin: auto;
          @include sp{
            width: 100%;
          }
          @include mt(30);
          &_part{
            text-align: center;
            width: calc(240 / 780 * 100%);
            >div{
              overflow: hidden;
              border-radius: 100vw;
            }
            figcaption{
              font-weight: 700;
              margin-top: .5em;
              line-height: calc(21/18);
              @include sp{
                font-size: vw(26, 750);
              }
            }
          }
          &_arrows{
            width: calc(300 / 780 * 100%);
          }
        }
        &_box{
          width: calc(900 / 1512 * 100%);
          margin: auto;
          @include mt(60);
          color: #FFF;
          background-color: rgba(#000,.8);
          padding: calc(50 / 1512 * 100%) calc(50 / 1512 * 100%) calc(30 / 1512 * 100%);
          @include sp{
            width: 100%;
            padding: 3rem 1.5rem 2em;
          }
          &_ttl{
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            @include fz(36);
            line-height: calc(42/36);
            &--sub{
              display: inline-block;
            }
            &--main{
              display: inline-block;
              @include fz(48);
            }
          }
          &_list{
            @include mt(20);
            >li{
              display: flex;
              align-items: flex-start;
              @include fz(24);
              line-height: calc(36/24);
              font-family: $font_yumin;
              font-weight: 700;
              padding: 2em 0;
              @include sp{
                font-size: vw(26, 750);
                padding: 1.5em 0;
              }
              &:not(:last-child){
                background-image : linear-gradient(to right, #FFF, #FFF 4px, transparent 4px, transparent 8px);  /* 幅2の線を作る */
                background-size: 8px 1px;
                background-position: left bottom;
                background-repeat: repeat-x;
              }
              figure{
                width: calc(60 / 800 * 100%);
                margin-right: calc(20 / 800 * 100%);
                background-color: #fff;
                border: 1px solid #333333;
              }
              div{
                flex: 1;
                .c-txt-16{
                  @include sp{
                    font-size: vw(22, 750);
                  }
                }
              }
            }
            &.js-scroll{
              >li{
                figure{
                  img{
                    opacity: 0;
                  }
                }
              }
              &.show{
                @for $i from 1 through 10 {
                  >li:nth-child(#{$i}) {
                    
                    figure{
                      img{
                        animation-name: popIn-big;
                        animation-duration: .3s;
                        animation-timing-function: ease;
                        animation-fill-mode: forwards;
                        animation-delay: #{0.6*$i}s;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &Apply{
    position: relative;
    &_bg{
      margin-top: calc(-270 / 1512 * 100%);
    }
    &_inner{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      padding-top: calc((270 - 30) / 1512 * 100%);
      @include sp{
        padding-top: calc((135 + 0) / 750 * 100%);
      }
    }
    &_btn{
      width: calc(600 / 1512 * 100%);
      margin: auto;
      @include sp{
        width: calc(400 / 750 * 100%);
      }
      .c-btn_apply--type01{
        >span{
          padding: 3.3rem 0;
          @include sp{
            padding: 2rem 0;
          }
          img{
            width: calc(306 / 600 * 100%);
            @include sp{
              width: calc(306 / 400 * 100%);
            }
          }
        }
      }
    }
  }
  &Career{
    &_head{
      position: relative;
      &_bg{
        margin-top: calc(-270 / 1512 * 100%);
      }
      &_inner{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding-top: calc((270) / 1512 * 100%);
      }
      &_ttl{
        text-align: center;
        @include fz(48);
        line-height: calc(84/48);
        font-family: $font_yumin;
        >span{
          position: relative;
          display: inline-block;
          padding-bottom: .3em;
          &:before{
            position: absolute;
            content: "";
            width: 1.5em;
            height: 1px;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            background-color: $base_color;
          }
        }
      }
      &_ex{
        display: flex;
        justify-content: space-between;
        width: calc(920 / 1512 * 100%);
        margin: auto;
        @include mt(80);
        @include sp{
          width: calc(660 / 750 * 100%);
          justify-content: space-around;
        }
        &Item{
          text-align: center;
          width: calc(360 / 920 * 100%);
          @include sp{
            width: calc(280 / 660 * 100%);
          }
          p{
            line-height: calc(36/24);
            @include fz(24);
            font-weight: 700;
            margin-bottom: 1em;
            @include sp{
              font-size: vw(26,750);
            }
          }
          figure{
              overflow: hidden;
              border-radius: 100vw;
          }
          &_ttl{
            display: inline-block;
            width: calc(300 / 360 * 100%);
            margin-top: -1rem;
            @include sp{
              margin-top: .5em;
            }
          }
        }
      }
      &_label{
        letter-spacing: -0.1em;
      }
    }
    &_flow{
      position: relative;
      &_bg{
        margin-top: calc(-270 / 1512 * 100%);
      }
      &_inner{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding-top: calc((270 + 80) / 1512 * 100%);
        @include sp{
          padding-top: calc((135 + 60) / 750 * 100%);
        }
      }
      &_list{
        display: flex;
        flex-direction: column;
        align-items: center;
        @include fz(28);
        margin-left: -16em;
        padding-top: 1em;
        padding-bottom: 1em;
        overflow: hidden;
        @include sp{
          margin-left: -9.5em;
        }
        >li{
          position: relative;
          padding-left: 3em;
          @include sp{

          }
          @for $i from 1 through 10 {
            &:nth-child(#{$i}) {
              margin-left: #{4.23*$i}em;
              @include sp{
                margin-left: #{2.3*$i}em;
              }
            }
          }
          figure{
            position: absolute;
            z-index: 1;
            overflow: hidden;
            border-radius: 100vw;
            width: 4.4em;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
          >p{
            display: inline-block;
            line-height: calc(38/28);
            min-width: 13.5em;
            font-weight: 500;
            transform: skew(15deg);
            background-color: #000;
            @include sp{
              transform: skew(9deg);
            }
            >span{
              display: inline-block;
              padding: .8em 2em .8em 3em;
              color: #FFF;
              transform: skew(-15deg);
              @include sp{
                transform: skew(-9deg);
              }
            }
          }
          >span{
            position: absolute;
            top: 50%;
            left: calc(100% + 3em);
            white-space: nowrap;
            transform: translateY(- .8em);
            line-height: calc(28/16);
            @include fz(20);
            font-weight: 500;
            @include sp{
              top: calc(100% + 1em);
              left: 73%;
              transform: none;
            }
            >span{
              @include fz(16);
            }
          }
          &:not(:last-child){
            margin-bottom: 5em;
          }
          &:first-child{
            &:before{
              position: absolute;
              content: "";
              width: 1px;
              height: 34em;
              left: 14em;
              top: 0;
              transform: rotate(-15deg);
              background-color: #000;
              @include sp{
                transform: rotate(-9deg);
                left: 12.5em;
              }
            }
            >span{
              &:after{
                position: absolute;
                content: "";
                width: 1px;
                height: 46em;
                left: 6.55em;
                top: .5em;
                transform: rotate(-15deg);
                background-image : linear-gradient(to bottom, #000 1px, transparent 1px);
                background-size: 1px 8px;
                background-repeat: repeat-y; 
                background-position: left bottom;
                @include sp{
                  display: none;
                }
              }
            }
          }
          &:last-child{
            margin-bottom: 4em;
          }
        }
        &.js-scroll-delay{
          >li:first-child{
            &:before{
              opacity: 0;
              transition: opacity .6s 1.8s;
            }
            >span{
              &:after{
                opacity: 0;
                transition: opacity .6s 1.8s;
              }
            }
          }
          &.show{
            >li:first-child{
              &:before{
                opacity: 1;
              }
              >span{
                &:after{
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }
  &ApplyBtm{
    background-color: red;
    background: url(../../assets/img/bg_indexApply_pc.jpg) no-repeat;
    background-size: cover;
    &_inner{
      @include pt(80);
      @include pb(80);
    }
    &_txt{
      text-align: center;
      // @include fz(36);
      // line-height: calc(60/36);
      // font-weight: 700;
      p{
        display: inline-block;
        width: calc(625 / 1512 * 100%);
        @include sp{
          width: calc(600 / 750 * 100%);
        }
      }
    }
    &_btn{
      @include pt(50);
      width: calc(600 / 1512 * 100%);
      margin: auto;
      @include sp{
        width: calc(400 / 750 * 100%);
      }
      .c-btn_apply--type01{
        >span{
          padding: 3.3rem 0;
          @include sp{
            padding: 2rem 0;
          }
          img{
            width: calc(306 / 600 * 100%);
            @include sp{
              width: calc(306 / 400 * 100%);
            }
          }
        }
      }
    }
  }
}