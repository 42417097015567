@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes heroSwich {
  0% {
		opacity: 1;
		transform: rotateY(180deg);
	}
	10% {
		transform: rotateY(0deg);
	}
	50%{
		transform: rotateY(0deg);
	}
	60%{
		transform: rotateY(180deg);
	}
  100% {
		opacity: 1;
		transform: rotateY(180deg);
  }
}


@keyframes heroSwich_02 {
  0% {
		opacity: 0;
	}
	10%{
		opacity: 1;
	}
	50%{
		opacity: 1;
	}
	60%{
		opacity: 0;
	}
  100% {
		opacity: 0;
  }
}

@keyframes slideScreen {
  0% {
		opacity: 1;
    -webkit-clip-path: inset(0 100% 0 0);
    clip-path: inset(0 100% 0 0);
  }
	10% {
    -webkit-clip-path: inset(0);
    clip-path: inset(0);
  }
  50% {
    -webkit-clip-path: inset(0);
    clip-path: inset(0);
  }
	60% {
		opacity: 1;
    -webkit-clip-path: inset(0 0 0 100%);
    clip-path: inset(0 0 0 100%);
  }
	100% {
		opacity: 1;
    -webkit-clip-path: inset(0 0 0 100%);
    clip-path: inset(0 0 0 100%);
  }
}

@keyframes slideLeft {
  0% {
    -webkit-clip-path: inset(0 100% 0 0);
    clip-path: inset(0 100% 0 0);
  }
  100% {
    -webkit-clip-path: inset(0);
    clip-path: inset(0);
  }
}

@keyframes slideRight {
  0% {
    -webkit-clip-path: inset(0 0 0 100%);
    clip-path: inset(0 0 0 100%);
  }
  100% {
    -webkit-clip-path: inset(0);
    clip-path: inset(0);
  }
}

@keyframes shine {
  0% {
    opacity: 0;
    transform: translateX(100%) skew(-45deg);
  }
	89% {
    opacity: 0;
    transform: translateX(100%) skew(-45deg);
  }
	90% {
    opacity: 1;
    transform: translateX(100%) skew(-45deg);
  }
	
  100% {
    opacity: 1;
    transform: translateX(-100%) skew(-45deg);
  }
}

@keyframes popIn {
  0% {
		opacity: 1;
		transform: scale(0.7);
	}
	60% {
		opacity: 1;
		transform: scale(1.1);
	}
  100% {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes popIn-big {
  0% {
		opacity: 1;
		transform: scale(0.7);
	}
	60% {
		opacity: 1;
		transform: scale(1.2);
	}
  100% {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes pop {
  0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
  100% {
		transform: scale(1);
	}
}

@keyframes slideLeft {
  0% {
    -webkit-clip-path: inset(0 100% 0 0);
    clip-path: inset(0 100% 0 0);
  }
  100% {
    -webkit-clip-path: inset(0);
    clip-path: inset(0);
  }
}
@keyframes slideRight {
  0% {
    -webkit-clip-path: inset(0 0 0 100%);
    clip-path: inset(0 0 0 100%);
  }
  100% {
    -webkit-clip-path: inset(0);
    clip-path: inset(0);
  }
}

.headanime{
	&.js-scroll{
		.anime-bg{
			opacity: 0;
    	clip-path: inset(0 0 0 100%);
		}
		.anime-inner{
			opacity: 0;
		}
		.anime-inner-fadeInUp{
			opacity: 0;
			transition: opacity 1s .5s,transform 1s .5s;
			transform: translateY(20px);
		}
		.anime-label{
			opacity: 0;
			transition: opacity .5s 1s,transform .5s 1s;
			transform: translate(-100%,54%);
		}
		&.show{
			.anime-bg{
				opacity: 1;
				animation: slideLeft;
				animation-duration: 1s;
				animation-timing-function: ease;
				animation-fill-mode: forwards;
			}
			.anime-inner{
				animation-name: popIn;
				animation-duration: .5s;
				animation-delay: .5s;
				animation-timing-function: ease;
				animation-fill-mode: forwards;
			}
			.anime-inner-fadeInUp{
				opacity: 1;
				transform: translateY(0);
			}
			.anime-label{
				opacity: 1;
				transform:  translate(0,0);
			}
		}
	}
}

.p-indexPoint_listItem{
	&.js-scroll{
		opacity: 0;
    clip-path: inset(0 0 0 100%);
		&.show{
			opacity: 1;
			animation: slideLeft;
			animation-duration: 1s;
			animation-timing-function: ease;
			animation-fill-mode: forwards;
		}
	}
}

.fadeInUp{
	&.js-scroll{
		opacity: 0;
		transform: translate3d(0, 20px, 0);
		&.show{
			animation-name: fadeInUp;
			animation-duration: 1s;
			animation-timing-function: ease;
			animation-fill-mode: forwards;
		}
		&.done{
			
		}
	}
}

.fadeInUp{
	&.js-scroll-delay{
		>*{
			opacity: 0;
			transform: translate3d(0, 20px, 0);
		}
		&.show{
			@for $i from 1 through 10 {
				>*:nth-child(#{$i}) {
					animation-name: fadeInUp;
					animation-duration: 1s;
					animation-timing-function: ease;
					animation-fill-mode: forwards;
					animation-delay: #{0.3*$i}s;
				}
			}
		}
	}
}
