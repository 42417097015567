@charset "UTF-8";

/*-------------------------------
  footer
-------------------------------*/

.l-footer{
  background-color: $base_color;
  &_inner{
    @include pt(40);
    @include pb(30);
    width: calc(1372 / 1512 * 100%);
    margin: auto;
    @include sp{
      width: calc(660 / 750 * 100%);
    }
    &_row{
      display: flex;
      justify-content: space-between;
      color: #FFF;
      font-family: $font_yumin;
      @include fz(16);
      line-height: calc(28/16);
      font-weight: 500;
      @include sp{
        flex-direction: column;
        font-size: vw(24,750);
      }
    }
  }
}

.footer_logo{
  width: calc(184 / 1372 * 100%);
  @include mb(40);
  @include sp{
    width: calc(188 / 660 * 100%);
  }
}
.copyright{
  @include sp{
    margin-left: auto;
  }
}

.pageTop{
  position: fixed;
  z-index: 9999;
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  bottom: 30px;
  right: 30px;
  background-color: $base_color;
  cursor: pointer;
  @include sp {
    width: 30px;
    height: 30px;
    bottom: 2%;
    right: 3%;
  }
  &:before{
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    top: 40%;
    left: 0;
    right: 0;
    margin: auto;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 10px solid #FFF;
    @include sp {
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-bottom: 5px solid #FFF;
    }
  }
}

