.c-btn{
	cursor: pointer;
	text-decoration: none;
	$this: &;
	&_apply{
		&--type01{
			@extend #{$this};
			width: 100%;
			overflow: hidden;
			display: inline-block;
			background: linear-gradient(135deg,#D0A942, #E7CA63, #CDA63F, #F3F4CB, #AA7821);
			box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.6);
			padding: 3px;
			border-radius: 15px;
			@include sp{
				padding: 2px;
				border-radius: 10px;
			}
			>span{
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 13px;
				padding: 1.3rem 0;
				background-color: $green_line;
				@include sp{
					padding: .8rem 0;
					border-radius: 9px;
				}
				img{
					width: calc(306 / 360 * 100%);
				}
				&:before,&:after{
					position: absolute;
					content: "";
					width: 50%;
					height: 100%;
					top: 0;
					left: 0;
					background:linear-gradient(to right,rgba(#FFF,.3),transparent);
					transform: translateX(120%) skew(-45deg);
					
				}
				&:before{
					animation-name: shine;
					animation-duration: 4s;
					animation-timing-function: ease;
					animation-iteration-count: infinite
				}
			}
			@include pc{
				&:hover{
					animation: pop;
					animation-duration: .3s;
					animation-timing-function: ease;
					animation-fill-mode: forwards;
				}
			}
		}
	}
	&_label{
		@extend #{$this};
		position: absolute;
		z-index: 2;
		bottom: 0;
		left: 0;
		width: calc(1008 / 1512 * 100%);
		display: block;
		display: flex;
		color: #FFF;
		@include fz(60);
		font-weight: 500;
		font-family: $font_yumin;
		@include pcOver{
			font-size: 60px;
		}
		@include sp{
			width: 100%;
			@include fz(48);
		}
		&:before{
			display: block;
			content: "";
			background: #000;
			width: 100%;
			padding-top: calc(360 / 1080 * 100%);
			clip-path: polygon(0 0, 0% 100%, 100% 46.5%);
		}
		>span{
			position: absolute;
			display: inline-block;
			line-height: 1;
			top: 50%;
			left: calc(120 / 1512 * 100%);
			transform: translate(0,-50%);
			@include pcOver{
				left: calc(310 / 1512 * 100%);
			}
			@include sp{
				left: calc(45 / 750 * 100%);
			}
		}
	}
}