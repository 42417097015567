@charset "utf-8";


/*------------------------
 form リセットCSS
------------------------*/

form{
	input,
	button,
	select,
	textarea {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		background: transparent;
		border: none;
		border-radius: 0;
		font: inherit;
		outline: none;
		&::placeholder {
			color: #bbbbc1 !important;
		}
		&:focus-visible {
      outline: -webkit-focus-ring-color auto 1px;
    }
	}

	textarea {
		resize: vertical;
	}

	input[type='checkbox'],
	input[type='radio'] {
		display: none;
	}

	input[type='submit'],
	input[type='button'],
	label,
	button,
	select {
		cursor: pointer;
	}

	select::-ms-expand {
		display: none;
	}
	select{
		width: 100%;
		max-width: 100% !important;
		option{
			width: 100%;
			max-width: 100% !important;
		}
	}
}

/*------------------------
 END form リセットCSS
------------------------*/


//input[type=text]

.br_box {
	display: block;
	width: 100%;
	height: calc(1em + 1.9em);
	padding: 1em 1em;
	$lh: 28/16;
	line-height: $lh;
	color: $base_color;
	background-color: #fafafa;
	border: 0.0625em solid #c2c2c2;
	box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
}

//input[type=textarea]

.textarea {
	display: block;
	width: 100%;
	padding: 1em 1em;
	color: $base_color;
	background-color: #fafafa;
	border: 0.0625rem solid #9da2a7;
	box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
}

//input[type=radio]

.mwform-radio-field{
	display: inline-block;
	padding: .2em 0;
	label{
		display: inline-block;
	}
	input[type=radio] + span {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		cursor: pointer;
		padding-left: 2.6em;
	}
	input[type=radio] + span:after {
		position: absolute;
		content: "";
		width: 1.6em;
		height: 1.6em;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		border: 1px solid $base_color;
		border-radius: 50%;
	}
	input[type=radio] + span:before {
		position: absolute;
		content: "";
		width: 1.6em;
		height: 1.6em;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		background-color: $base_color;
		border: 5px solid #FFF;
		border-radius: 50%;
		opacity: 0;
	}
	input[type=radio]:checked + span:before {
		opacity: 1;
	}
}


//input[type=checkbox]

.mwform-checkbox-field{
	display: inline-block;
	label{
		display: inline-block;
	}
	input[type=checkbox] + span {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		cursor: pointer;
		padding-left: 2.6em;
	}
	input[type=checkbox] + span:after {
		position: absolute;
		content: "";
		width: 1.6em;
		height: 1.6em;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		border: 1px solid $base_color;
	}
	input[type=checkbox] + span:before {
		position: absolute;
		content: "";
		width: 1.6em;
		height: 1.6em;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		background-color: $base_color;
		border: 5px solid #FFF;
		opacity: 0;
	}
	input[type=checkbox]:checked + span:before {
		opacity: 1;
	}
}


//同意チェックボックス
.agreement-checkbox{
	display: inline-flex;
	align-items: center;
	text-align: center;
	padding: .2em 0;
	label{
		display: inline-block;
		$lh: 32/16;
  	line-height: $lh;
	}
	input[type=checkbox] + span {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		cursor: pointer;
		padding-left: 2.6em;
	}
	input[type=checkbox] + span:after {
		position: absolute;
		content: "";
		width: 1.6em;
		height: 1.6em;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		border: 1px solid $base_color;
	}
	input[type=checkbox] + span:before {
		position: absolute;
		content: "";
		width: 0.5em;
    height: 1.2em;
    top: calc(50% - 0.2em);
    left: 0.6em;
		transform: translateY(-50%) rotate(42deg);
		border-right: 2px solid $base_color;
		border-bottom: 2px solid $base_color;
		opacity: 0;
	}
	input[type=checkbox]:checked + span:before {
		opacity: 1;
	}
}


.error{
	display: inline-block;
	padding-top: .5em;
	color: red;
	@include sp {
		padding-top: 0.25em;
	}
}

.require{
	display: inline-block;
	color: #FFF;
	background-color: $base_color;
	padding: 0 2.3em;
	margin-left: 1.5em;
	font-size: 0.875em;
	$lh: 28/14;
	line-height: $lh;
	font-weight: 400;
}


.policyTxt{
	overflow-y: scroll;
	max-height: 13.8em;
	padding: 1em;
	color: $base_color;
	$lh: 36/16;
	line-height: $lh;
	border: 0.0625em solid #9da2a7;
	background-color: #fafafa;
	margin-bottom: 10px;
}


//テーブル
table.form_table{
	width: 100%;
	border-collapse: collapse;
	tbody{
		tr{
			th,td{
				$lh: 28/16;
				line-height: $lh;
				font-size: 16px;
				@include sp {
					@include fz-vw(14);
				}
			}
			th{
				display: block;
				width: 100%;
				font-weight: 700;
				padding-top: 20px;
				padding-bottom: 20px;
			}
			td{
				display: block;
				width: 100%;
				padding-bottom: 20px;

			}
		}
	}
}


//同意ボタン
.form_agreement{
	margin-bottom: 5rem;
	@include sp {
		margin-bottom: 2.5rem;
	}
	.form_agreement_checkbox{
		label span{
			font-weight: 700;
		}
	}
	.form_agreement_box{
		height: 240px;
		padding: 4.1666%;/*1200:50*/
		overflow-y: scroll;
		border: 1px solid #ebebeb;
		margin-bottom: 5rem;
		@include sp {
			margin-bottom: 2.5rem;
		}
		dl{
			margin-bottom: 1.6rem;
			dt{
				font-weight: 700;
				margin-bottom: 0.2em;
			}
		}
	}
}


.form_btn_area{
	display: flex;
	justify-content: center;
	@include sp {
		flex-direction: column;
	}
}


//確認・戻る・送信：ボタン
input[type=submit],button[type=submit]{

}

//確認ボタン disabled
input[type=submit]:disabled{
	cursor: unset;
	&:hover{

	}
}



/*------------------------
 mw_wp_form
------------------------*/

//入力画面
.mw_wp_form.mw_wp_form_input{

}

//確認画面
.mw_wp_form.mw_wp_form_confirm{

}

//確認画面
.mw_wp_form.mw_wp_form_complete{

}
